import React from 'react'

import { asText } from '../utils/prismic'

// component
// ==========================================================================================
const Title = ({ content, tag, className }) => {
  const copy = asText(content)

  if (!copy) {
    return null
  }

  return React.createElement(tag, { className }, copy)
}

export default Title
